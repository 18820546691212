

import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import KisiForm from "@/components/forms/KisiForm.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {CinsiyetLabel} from "@/enum/Cinsiyet";

@Component({
  components: {FormDialog, KisiForm}
})
export default class KisiInfo extends Vue {
  @Prop()item!: KisiEntity;
  cinsiyetLabels = CinsiyetLabel;

  load(){
    this.$emit('load')
  }
}
