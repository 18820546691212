
import {Component, Vue} from "vue-property-decorator";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import KisiInfo from "@/components/infos/KisiInfo.vue";
import IletisimList from "@/components/lists/IletisimList.vue";
import AdresSelectableList from "@/components/lists/AdresSelectableList.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import DokumanList from "@/components/lists/DokumanList.vue";
import TakipList from "@/components/lists/TakipList.vue";
import KurumInfo from "@/components/infos/KurumInfo.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {TakipEntity} from "@/entity/TakipEntity";

@Component({
  components: {
    KurumInfo,
    TakipList,
    DokumanList,
    BankaHesabiList,
    AdresSelectableList,
    IletisimList,
    KisiInfo,
  }
})
export default class MuvekkilDetayView extends Vue {
  muvekkilItem: MuvekkilEntity|null=null;
  kisiKurumItem: KisiEntity | KurumEntity | null = null;
  muvekkilTakipleri: Array<TakipEntity> =[];

  get action() {
    if (this.muvekkilItem?.kisi_id)
      return "/api/v1/kisi/" + this.kisiKurumItem?.id;
    else
      return "/api/v1/kurum/" + this.kisiKurumItem?.id;
  }

  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'muvekkil-detay'}
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  load() {
    this.$http.get('/api/v1/muvekkil/' + this.$route.params.id).then(muvekkilResponse => {
      this.muvekkilItem = muvekkilResponse.data;
      let action = '/api/v1/' + (muvekkilResponse.data.kisi_id ? 'kisi' : 'kurum') + '/' + (muvekkilResponse.data.kisi_id ? muvekkilResponse.data.kisi_id : muvekkilResponse.data.kurum_id);
      this.$http.get(action).then(kisiResponse => {
        if (muvekkilResponse.data.kisi_id) {
          this.kisiKurumItem = kisiResponse.data.kisi;
        } else {
          this.kisiKurumItem = kisiResponse.data;
        }
      });
      let takipIdleri: Array<number> = [];
      muvekkilResponse.data.takipler.forEach((takip:any)=> takipIdleri.push(takip.id));
      if (takipIdleri.length > 0)
        this.$http.post('/api/v1/muvekkil/takip-listesi', takipIdleri).then((res) => {
          this.muvekkilTakipleri = res.data;
        });
    });
  }

  onDefaultAdresChange(adres: AdresEntity) {
    if (adres.adres_turu_id) {
      this.$http.put(this.action + '/varsayilan-adres', adres).then(() => this.load());
    }
  }
}
