

import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {KurumEntity} from "@/entity/KurumEntity";
import KurumForm from "@/components/forms/KurumForm.vue";

@Component({
  components: {FormDialog, KurumForm}
})
export default class KurumInfo extends Vue {
  @Prop({}) action!: string;
  @Prop() items !: KurumEntity;

  load() {
    this.$emit('load')
  }

}
